import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"
import Swiper from "react-id-swiper"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "swiper/css/swiper.css"
import DynamicForm from "../components/dynamicForm"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { Accordion, Card, Button } from "react-bootstrap"
import "react-tabs/style/react-tabs.css"
// const slugify = require("slugify")
import Sticky from "react-sticky-el"
import PageHeadingTitle from "@components/pageHeadingTitle"

const useViewport = () => {
  const [width, setWidth] = React.useState(
    typeof window !== "undefined" && window.innerWidth
  )
  // Add a second state variable "height" and default it to the current window height
  const [height, setHeight] = React.useState(
    typeof window !== "undefined" && window.innerHeight
  )

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      // Set the height in state as well as the width
      setHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  // Return both the height and width
  return { width, height }
}

const ProductInfo = ({ sec }) => {
  const params = {
    pagination: {
      speed: 500,
      slidesPerView: 2,
      loop: true,
      autoPlay: true,
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: "#swiper-next",
      prevEl: "#swiper-prev",
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
    },
    spaceBetween: 0,
    autoHeight: true,
  }
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: marked(sec.content || ""),
        }}
      />
      <div className="product-content-swiper-wrapper">
        {sec.boxes.length > 1 ? (
          <>
            <div className="product-content-swiper-nav">
              <button
                className="btn btn-primary swiper-nav-btn product-content-prev"
                id="swiper-prev"
              >
                <i className="fa fa-long-arrow-left"></i>
              </button>
              <button
                className="btn btn-primary swiper-nav-btn product-content-next"
                id="swiper-next"
              >
                <i className="fa fa-long-arrow-right"></i>
              </button>
            </div>
            <div className="row justify-content-center">
              <div className="col-10">
                <LazyLoadComponent>
                  <Swiper {...params}>
                    {sec.boxes.map((box, index) => (
                      <div>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12" key={index}>
                              {box?.banner?.asset?.fluid?.src ? (
                                <img
                                  src={box?.banner?.asset?.fluid?.src}
                                  alt="images"
                                  style={{
                                    maxWidth: "100%",
                                  }}
                                />
                              ) : (
                                <div className="embed-container">
                                  <iframe
                                    src={box?.videobanner}
                                    frameborder="0"
                                    allowfullscreen
                                    title="video banner"
                                  ></iframe>
                                </div>
                              )}

                              <h2 className="mt-3">{box.title}</h2>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: marked(box.content || ""),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Swiper>
                </LazyLoadComponent>
              </div>
            </div>
          </>
        ) : (
          <div>
            <Tabs>
              <Sticky
                className="sticky-tabs-wrapper"
                stickyClassName="is-sticky-tabs"
                boundaryElement=".react-tabs"
                stickyStyle={{
                  transform: "unset",
                  zIndex: "3",
                }}
              ></Sticky>
              <div className="tabs-menu ">
                <TabList>
                  {sec
                    ? sec.tabs.map((tab, index) => (
                        <Tab key={index}>{tab.title}</Tab>
                      ))
                    : null}
                </TabList>
              </div>
              <div className="container mt-5">
                {sec.tabs.map((tab_content, index) => (
                  <TabPanel key={index}>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked(tab_content.content || ""),
                        }}
                      />
                    </div>
                  </TabPanel>
                ))}
              </div>
            </Tabs>

            {sec.boxes.map((box, index) => (
              <>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-md-10" key={index}>
                      {box?.banner?.asset?.fluid?.src ? (
                        <img
                          src={box?.banner?.asset?.fluid?.src}
                          alt="images"
                          style={{ maxWidth: "100%" }}
                        />
                      ) : (
                        <div className="embed-container">
                          <iframe
                            src={box?.videobanner}
                            frameborder="0"
                            allowfullscreen
                            title="video banner"
                          ></iframe>
                        </div>
                      )}

                      <h2 className="mt-3">{box.title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked(box.content || ""),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
            {sec.title === "Gallery" && (
              <div>
                <ProductGallery images={sec.gallery} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const ProductTemplate = ({ data }) => {
  const { product, applicationList } = data
  const { width } = useViewport()
  const breakpoint = 768

  console.log("test", product)
  return (
    <Layout>
      <SEO title={product.title} description={product.excerpt} />
      <div className="page-headline pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <PageHeadingTitle h1={"Information Center"} h2={product.title}/>
              <div className="header-application text-center">
                <h4 className="font-weight-bold text-primary">
                  Browse by Application
                </h4>
                <ul className="list-inline application mb-0">
                  <li className="list-inline-item my-2">
                    <Link to="/products">
                      <span className="px-3 py-2 ">All</span>
                    </Link>
                  </li>
                  {applicationList.nodes.map(app => (
                    <li key={app.id} className="list-inline-item my-2">
                      <Link to={`/products/${app.slug.current}`}>
                        {" "}
                        <span className="px-3 py-2 ">{app.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-body products pt-0">
        {product.mainImage !== null ? (
          <div className="text-center py-5 ">
            <img
              src={product.mainImage.asset.fluid.src}
              alt="product"
              className="img-fluid"
            />
          </div>
        ) : null}
        <div
          className="container"
          dangerouslySetInnerHTML={{
            __html: marked(product.excerpt || ""),
          }}
        />

        {width < breakpoint ? (
          <div className="content mt-0">
            <LazyLoadComponent>
              {product
                ? product.section.map((sec, index) => (
                    <Accordion defaultActiveKey={1}>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={sec.idsection}
                          >
                            {sec.title}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={sec.idsection}>
                          <Card.Body>
                            <ProductInfo sec={sec} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  ))
                : null}
            </LazyLoadComponent>
          </div>
        ) : (
          <div className="tabs">
            <Tabs>
              <Sticky
                className="sticky-tabs-wrapper"
                stickyClassName="is-sticky-tabs"
                boundaryElement=".react-tabs"
                stickyStyle={{ transform: "unset", zIndex: "3" }}
              >
                <div className="tabs-menu ">
                  <TabList>
                    {product
                      ? product.section.map((product_tabs, index) => (
                          <Tab>{product_tabs.title}</Tab>
                        ))
                      : null}
                  </TabList>
                </div>
              </Sticky>
              <div className="content">
                <div className="container">
                  {product
                    ? product.section.map((sec, index) => (
                        <TabPanel>
                          <ProductInfo sec={sec} />
                        </TabPanel>
                      ))
                    : null}
                </div>
              </div>
            </Tabs>
          </div>
        )}

        <div
          className="container"
          dangerouslySetInnerHTML={{
            __html: marked(product.body || ""),
          }}
        />

        <div className="container mt-5">
          <DynamicForm />
        </div>
      </div>
    </Layout>
  )
}

const ProductGallery = ({ images }) => {
  const [mainImage, setMainImage] = useState(images[0])
  return (
    <div className="product-image-gallery">
      <div>
        <img
          className="img-fluid w-100"
          src={mainImage?.image?.asset?.fluid?.src}
          alt="Gallery"
        />
      </div>
      <div className="d-flex flex-wrap align-items-center mt-3">
        {images.map((image, index) => (
          <img
            key={index}
            className="img-fluid mr-1 mb-1"
            src={image?.image?.asset?.fluid?.src}
            style={{ maxHeight: 70, cursor: "pointer" }}
            onClick={() => setMainImage(image)}
            onKeyDown={() => setMainImage(image)}
            alt="Gallery Thumbnail"
            role="presentation"
          />
        ))}
      </div>
    </div>
  )
}

export default ProductTemplate

export const productQuery = graphql`
  query ProductTemplateQuery($id: String!) {
    product: sanityProduct(id: { eq: $id }) {
      id
      title
      excerpt
      section {
        title
        idsection
        content
        boxes {
          title
          content
          banner {
            asset {
              fluid {
                src
              }
            }
          }
          videobanner
        }
        gallery {
          image {
            asset {
              fluid {
                src
              }
            }
          }
        }
        tabs {
          title
          content
        }
      }
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
    }
    applicationList: allSanityApplication {
      nodes {
        id
        title
        excerpt
        slug {
          current
        }
      }
    }
  }
`
